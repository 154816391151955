
function Day22({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="daytwelve">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 22</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    Foto mit Motto<br/><br/>
    <div className="pb-5">
      Macht heute zusammen ein Foto mit dem Motto "Der schönste Ort des Tages"<br/><br/>    
    </div>
      </div>
   </div>
    </div>

  );
}

export default Day22;