import simpleHasher from "../helpers/hash"
import Day14 from "./Day14";
import Day16 from "./Day16";
import Day17 from "./Day17";
import Day18 from "./Day18";
import Day19 from "./Day19";
import Day20 from "./Day20";
import Day21 from "./Day21";
import Day22 from "./Day22";
import Day23 from "./Day23";
import Day24 from "./Day24";
import DayEight from "./DayEight";
import DayFive from "./DayFive";
import DayFour from "./DayFour";
import DayNine from "./DayNine";
import DayOne from "./DayOne";
import DaySeven from "./DaySeven";
import DaySix from "./DaySix";
import DayTen from "./DayTen";
import Day13 from "./DayThirteen";
import DayThree from "./DayThree";
import DayTwelve from "./DayTwelve";
import DayTwo from "./DayTwo";

let contents = {
  1: <DayOne/>,
  2: <DayTwo/>,
  3: <DayThree/>,
  4: <DayFour/>,
  5: <DayFive />,
  6: <DaySix/>,
  7: <DayEight/>,
  8: <DaySeven/>,
  9: <DayNine/>,
  10: <DayTen/>,
  12: <DayTwelve/>,
  13: <Day13/>,
  14: <Day14/>,
  16: <Day16/>,
  17: <Day17/>,
  18: <Day18/>,
  19: <Day19/>,
  20: <Day20/>,
  21: <Day21/>,
  22: <Day22/>,
  23: <Day23/>,
  24: <Day24/>
}

function PageContainer({content}) {
  const params = new URLSearchParams(window.location.search);
  let hash = params.get("h");
  let unlocked = 0;
  let authenticated = 0;

  let day = new Date(Date.now()).getDate();

  let curr = -1;
  for (var i = 0; i <= 31; i++) {
    let testHash = simpleHasher(i);
    if (testHash == hash) {
        curr = i;
    }
  }

 unlocked = curr <= day && curr > 0;
 //unlocked = true;
  
  return (
    <div>
        {
            unlocked ? <div>
                <div className="day-content">{contents[curr]}</div>
            </div> :
            <div>
                Hier musst noch ein Paar Tage warten!
            </div> 
        }

    </div>
  );
}

export default PageContainer;