
function Day16({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="daythirteen">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 16</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5">
    Noch ein Cafe in Wien<br/><br/>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2659.001494229375!2d16.37176057598035!3d48.2065871463548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d079e597e2b1d%3A0x51bf27666a76795d!2sKleines%20Caf%C3%A9!5e0!3m2!1sde!2sde!4v1734292607227!5m2!1sde!2sde" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
      </div>
   </div>
    </div>

  );
}

export default Day16;