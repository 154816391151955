
function Day18({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="dayseven">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 18</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    Erster Urlaubstag!<br/><br/>
    <div className="pb-5">
      Heute ist unser erster Urlaubstag!<br/> Haltet heute 5 Momente auf eurer Kamera fest - Handy oder Sofortkamera<br/><br/>    
    </div>
      </div>
   </div>
    </div>

  );
}

export default Day18;