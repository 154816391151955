
function Day17({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="dayseventeen">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 17</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    Heute fahren wir in den Urli!!!<br/><br/>
    <div className="pb-5">
      ...und heute fangen wir an, unser gemeinsames Reisetagebuch zu schreiben!<br/><br/>    
    </div>
      </div>
   </div>
    </div>

  );
}

export default Day17;